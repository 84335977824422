<template>
  <div class="pageContol listWrap">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据中心</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">学员信息查询（安全生产）</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc">
          <div class="searchbox">
            <div title="学员信息" class="searchboxItem ci-full">
              <span class="itemLabel">学员信息:</span>
              <el-select v-model="searchData.userId" placeholder="请选择" no-data-text="没有数据" remote size="small" clearable
                :remote-method="superUserSelect" @visible-change="clearUserSearchModel">
                <template>
                  <div class="select-header">
                    <span style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 100px;
                      ">学员姓名</span>
                    <span style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 140px;
                      ">身份证号码</span>
                    <span style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 100px;
                      ">学员电话</span>
                  </div>
                  <div class="select-header">
                    <el-input v-model="searchData_User.seaUserName" v-on:input="superUserSelect" type="text"
                      size="small" placeholder="学员姓名" clearable style="width: 100px" />
                    <el-input v-model="searchData_User.seaUserIdcard" v-on:input="superUserSelect" type="text"
                      size="small" placeholder="身份证号码" clearable style="margin-left: 50px; width: 140px" />
                    <el-input v-model="searchData_User.seaUserMobile" v-on:input="superUserSelect" type="text"
                      size="small" placeholder="学员电话" clearable style="margin-left: 50px; width: 100px" />
                  </div>
                </template>
                <el-option v-for="(item, index) in searchData_User.userList" :key="index" :label="item.userName"
                  :value="item.userId">
                  <span style="width: 100px; font-size: 13px">{{ item.userName }}</span>
                  <span style="width: 140px; margin-left: 50px; font-size: 13px">{{ item.idcard }}</span>
                  <span style="width: 100px; margin-left: 50px; font-size: 13px">{{ item.mobile }}</span>
                </el-option>
              </el-select>
            </div>
            <div title="班级信息" class="searchboxItem ci-full">
              <span class="itemLabel">班级信息:</span>
              <el-select v-model="searchData.projectId" placeholder="请选择" remote size="small" clearable
                :remote-method="superProjectSelect" @visible-change="clearProjectSearchModel">
                <template>
                  <div class="select-header">
                    <span style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 150px;
                      ">班级编号</span>
                    <span style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 250px;
                      ">班级名称</span>
                  </div>
                  <div class="select-header">
                    <el-input v-model="searchData_Project.seaProjectCode" v-on:input="superProjectSelect" type="text"
                      size="small" placeholder="班级编号" clearable style="width: 150px" />
                    <el-input v-model="searchData_Project.seaProjectName" v-on:input="superProjectSelect" type="text"
                      size="small" placeholder="请输入班级名称" clearable style="margin-left: 50px; width: 250px" />
                  </div>
                </template>
                <el-option v-for="item in searchData_Project.projectList" :key="item.projectId"
                  :label="item.projectName" :value="item.projectId">
                  <span style="width: 150px; font-size: 13px">{{ item.projectCode }}</span>
                  <span style="width: 250px; margin-left: 50px; font-size: 13px">{{ item.projectName }}</span>
                </el-option>
              </el-select>
            </div>
            <div title="所属区划" class="searchboxItem ci-full">
              <span class="itemLabel">所属区划:</span>
              <el-cascader v-model="searchData.areaId" :options="areatreeList" :props="propsarea" clearable
                size="small"></el-cascader>
            </div>
            <div title="年龄" class="searchboxItem ci-full">
              <span class="itemLabel">年龄:</span>
              <el-input v-model="searchData.ageStart" @keyup.native="
            searchData.ageStart = zF.positiveInteger(searchData.ageStart)
            " type="text" size="small" placeholder="最小年龄" clearable style="width: 100px" />
              <span style="min-width: 10px; text-align: center">-</span>
              <el-input v-model="searchData.ageEnd" @keyup.native="
            searchData.ageEnd = zF.positiveInteger(searchData.ageEnd)
            " type="text" size="small" placeholder="最大年龄" clearable style="width: 100px" />
            </div>
          </div>
          <div class="searchbox" style="margin-top: 10px">
            <div title="入班时间" class="searchboxItem ci-full">
              <span class="itemLabel">入班时间:</span>
              <el-date-picker class="listInput" clearable size="small" v-model="searchData.times" type="daterange"
                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                value-format="yyyy-MM-dd"></el-date-picker>
            </div>
            <div title="机构名称" class="searchboxItem ci-full" v-if="['-1','2','-4'].includes(userJson.roleId)">
              <span class="itemLabel">机构名称:</span>
              <el-select size="small" v-model="searchData.compId" remote :remote-method="getCompanyList" filterable
                clearable placeholder="请至少输入两个字搜索">
                <el-option v-for="item in companyList" :key="item.compId" :label="item.compName"
                  :value="item.compId"></el-option>
              </el-select>
            </div>
            <div title="性别" class="searchboxItem ci-full">
              <span class="itemLabel">性别:</span>
              <el-select clearable size="small" v-model="searchData.sex" placeholder="请选择">
                <el-option v-for="item in sexList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div class="btnBox" style="margin-left: 20px">
              <el-button class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button class="bgc-bv" round @click="exportList()">导出</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" stripe :header-cell-style="tableHeader">
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="100" fixed />
              <el-table-column label="姓名" align="left" show-overflow-tooltip prop="userName" width="100" />
              <el-table-column label="身份证号" align="left" show-overflow-tooltip prop="idcard" width="180" />
              <el-table-column label="手机号" align="left" show-overflow-tooltip prop="mobile" width="120" />
              <el-table-column label="性别" align="left" show-overflow-tooltip prop="sex" width="80">
                <template slot-scope="scope">
                  {{ $setDictionary("SEX_REQUIREMENT", scope.row.sex) }}
                </template>
              </el-table-column>
              <el-table-column label="年龄" align="left" show-overflow-tooltip prop="age" width="80" />
              <el-table-column label="班级编码" align="left" show-overflow-tooltip prop="projectCode" width="180" />
              <el-table-column label="班级名称" align="left" show-overflow-tooltip prop="projectName" width="200" />
              <el-table-column label="培训类型" align="left" show-overflow-tooltip prop="trainTypeName" width="120" />
              <el-table-column label="培训类别" align="left" show-overflow-tooltip prop="postName" width="120" />
              <el-table-column label="认证状态" align="left" show-overflow-tooltip prop="authentication" width="100">
                <template slot-scope="scope">
                  {{ $setDictionary("AUTHENTICATION", scope.row.authentication) }}
                </template>
              </el-table-column>
              <el-table-column label="总课时" align="left" show-overflow-tooltip prop="totalLessonNum" width="120" />
              <el-table-column label="学习进度%" align="left" show-overflow-tooltip prop="percentage" width="110" />
              <el-table-column label="有效学习时长" align="left" show-overflow-tooltip prop="totalTime" width="120" />
              <el-table-column label="是否结业" align="left" show-overflow-tooltip prop="graduationState" width="100">
                <template slot-scope="scope">
                  {{ scope.row.graduationState ? "是" : "否" }}
                </template>
              </el-table-column>
              <el-table-column label="是否考试通过" align="left" show-overflow-tooltip prop="paperState" width="100" />
              <el-table-column label="考试次数" align="left" show-overflow-tooltip prop="paperNum" width="100" />
              <el-table-column label="考试成绩" align="left" show-overflow-tooltip prop="paperScore" width="100" />
              <el-table-column label="课程名称" align="left" show-overflow-tooltip prop="courseName" width="200" />
              <el-table-column label="单位名称" align="left" show-overflow-tooltip prop="compName" width="200" />
              <el-table-column label="所属区域" align="left" show-overflow-tooltip prop="userAreaName" width="200" />
              <el-table-column label="入班时间" align="left" show-overflow-tooltip prop="createTime" width="160" />
              <el-table-column label="操作" align="center" width="120" fixed="right">
                <template slot-scope="scope">
                  <el-button type="text" size="mini" @click="handledetail(scope.row)">查看学习详情</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "set_studentInformationQueryAQSC",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  computed: {
    ...mapGetters({
      userJson: "getUser",
      downloadItems: "getDownloadItems",
    }),
  },
  data() {
    return {
      // 检索数据
      searchData: {
        userId: "", // 学员id
        projectId: "", // 班级Id
        areaId: "", // 所属区划
        ageStart: "", // 年龄开始
        ageEnd: "", // 年龄截止
        times: [], // 入班时间
        compId: "", // 机构id
        sex: "", // 性别
      },
      // 学员信息 - 组件查询内容
      searchData_User: {
        seaUserName: "", // 姓名
        seaUserIdcard: "", // 身份证号
        seaUserMobile: "", // 手机号
        userList: [], // 学员数据
      },
      // 班级信息 - 组件查询内容
      searchData_Project: {
        seaProjectCode: "", // 班级编码
        seaProjectName: "", // 班级名称
        projectList: [], // 班级列表
      },
      // 行政区划 - 下拉数据
      areatreeList: [],
      // 行政区划 - 数据格式
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      // 机构名称 - 下拉数据
      companyList: [],
      // 性别 - 下拉数据
      sexList: [
        {
          value: '1',
          label: '男'
        },
        {
          value: '2',
          label: '女'
        }
      ],
    };
  },
  created() {
    this.superUserSelect();
    this.superProjectSelect();
    this.getAreatree();
  },
  methods: {
    // 获取 - 学员信息 - 下拉数据
    superUserSelect(e) {
      let url = "/biz/user/companyUserSelect";
      // 政府监管端,山西监管端
      if (["2", "-4"].includes(this.userJson.roleId)) {
        url = "/biz/user/areaUserSelect";
      }
      this.$post(
        url,
        {
          pageNum: 1,
          pageSize: 5,
          userName: this.searchData_User.seaUserName,
          idcard: this.searchData_User.seaUserIdcard,
          mobile: this.searchData_User.seaUserMobile,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length > 0) {
          this.searchData_User.userList = res.data.list;
        } else {
          this.searchData_User.userList = [{}];
        }
      });
    },
    // 获取 - 学员信息 - 下拉框出现/隐藏时触发
    clearUserSearchModel(e) {
      if (e) {
        this.searchData_User.seaUserName = "";
        this.searchData_User.seaUserIdcard = "";
        this.searchData_User.seaUserMobile = "";
        this.superUserSelect();
      }
    },
    // 获取 - 班级信息
    superProjectSelect(e) {
      let url = "/biz/project/superSelectProject";
      // 政府监管端,山西监管端
      if (["2", "-4"].includes(this.userJson.roleId)) {
        url = "/biz/project/areaSelectProject";
      }
      this.$post(
        url,
        {
          pageNum: 1,
          pageSize: 5,
          projectName: this.searchData_Project.seaProjectName,
          projectCode: this.searchData_Project.seaProjectCode,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length > 0) {
          this.searchData_Project.projectList = res.data.list;
        } else {
          this.searchData_Project.projectList = [];
        }
      });
    },
    // 获取 - 班级信息 - 清空
    clearProjectSearchModel(e) {
      if (e) {
        this.searchData_Project.seaProjectName = "";
        this.searchData_Project.seaProjectCode = "";
        this.superProjectSelect();
      }
    },
    // 获取行政区划
    getAreatree() {
      this.$post("/gov/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    // 获取签订机构数据
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", {
          compName: query.trim()
        }).then((res) => {
          this.companyList = res.data || [];
        }).catch(() => {
          return;
        });
      } else {
        this.companyList = [];
      }
    },
    // 获取列表数据
    getData(pageNum = 1) {
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        ...this.searchData,
      };
      if (this.searchData.times) {
        params.inClassStart = this.searchData.times[0];
        params.inClassEnd = this.searchData.times[1];
      }
      let url = "/biz/userInfoQuery/queryUserList";
      // 政府监管端,山西监管端
      if (["2", "-4"].includes(this.userJson.roleId)) {
        url = "/gov/userInfoQuery/queryUserList";
      }
      this.doFetch({
        url,
        params,
        pageNum,
      });
    },
    // 导出
    exportList() {
      let url = "/biz/userInfoQuery/export";
      // 政府监管端,山西监管端
      if (["2", "-4"].includes(this.userJson.roleId)) {
        url = "/gov/userInfoQuery/export";
      }
      let params = {
        ...this.searchData,
      };
      if (this.searchData.times) {
        params.inClassStart = this.searchData.times[0];
        params.inClassEnd = this.searchData.times[1];
      }
      this.$post(url, params)
        .then((res) => {
          const arr = [...res.data];
          for (let item of arr) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
        })
        .catch(() => {
          return;
        });
    },
    // 跳转学习详情
    handledetail(row) {
      this.$router.push({
        path: "/set/studentInformationQueryAQSCInfo",
        query: {
          userId: row.userId,
          projectCourseId: row.projectCourseId,
        },
      });
    },
  },
  beforeRouteLeave: resetKeepAlive,
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // 通过 `vm` 访问组件实例
      if (
        from.path == "/web/certificateManage/studentInformationQueryAQSCInfo"
      ) {
        vm.getData(vm.pageNum);
      }
    });
  },
};
</script>
<style lang="less" scoped>
.operationControl {
  display: flex;
  align-items: flex-start;
}

.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
